import React,{ useState } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkout from "../Controller/Checkout";
import Checkout2 from "../Controller/Checkout2";
import Shopify from "../Controller/Shopify";
import Payments from "../Controller/Payments";
import Thankyou from "../Controller/Thankyou";
import Ordercreate from "../Controller/Ordercreate";
import Success from "../Controller/Success";
import '../assets/css/theme.css';
import '../assets/bs-stepper/bs-stepper.css'
const Rotas = ()=>{
    const [ articles] = useState(null); 
    let logado = articles;
    return (
        <BrowserRouter>
            <Routes>
                {!logado && <Route path="/" element={<Checkout2 logado={logado}/>}/>}
                {!logado && <Route path="/chk" element={<Checkout logado={logado}/>}/>}
                {!logado && <Route path="/shopify" element={<Shopify logado={logado}/>}/>}
                {!logado && <Route path="/paymentstatus" element={<Payments logado={logado}/>}/>}
                {!logado && <Route path="/success" element={<Thankyou logado={logado}/>}/>}
                {!logado && <Route path="/successfull" element={<Success logado={logado}/>}/>}
                
            </Routes>
        </BrowserRouter>
    );
}
export default Rotas;